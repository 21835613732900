export default {
    getStockConcepts(state) {
        return state.stockConcepts
    },
    getDeposits(state) {
        return state.deposits
    },
    getPurchaseOrdersDetailPendingEntry(state) {
        return state.purchaseOrdersDetailPendingEntry
    },
    getStockMovementDetails(state) {
        return state.stockMovementDetails
    },
}