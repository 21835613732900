import {money} from "@/objects/shared/money";

export default {
    accountPlannings: [],
    ledgerAccountMovements: [],
    ledgerAccountBalances: {
        debitTotal: money(),
        creditTotal: money()
    },
    subCategories: [],
    commonAccountingEntries: [],
    paymentMethods: [],
    collectionMethods: [],
    supplierPaymentMethods: [],
    paymentMethodAccountParameters: [],
    periodClose: [],
    taxRegistries: [],
    taxTypes: []
}