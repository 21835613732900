<template>
  <v-app>
    <Navigation
        v-if="authenticated"
        @refresh-view="viewKey ++"
    >
    </Navigation>
    <!--    Dialogs -->
    <DialogLoading
        v-bind:is-loading="isLoading && !getLoadingTable"
    />
    <SnackBar
        v-bind:show="showSnackBar"
        v-bind:color="snackBarColor"
        v-bind:message="snackBarMessage"
        @close-snack="showSnackBar = false"
    />
    <AlertNotification
        v-bind:show="showAlertNotification"
        v-bind:type="alertType"
        v-bind:message="alertMessage"
        @hide-notification="showAlertNotification = false"
    />
    <v-main>
      <router-view
          :key="viewKey"
          @refresh-view="viewKey ++"
      />
    </v-main>
  </v-app>
</template>

<script>
import {mapGetters} from 'vuex';
import Navigation from './components/layout/Navigation.vue'
import axios from "axios";
import DialogLoading from "@/components/shared/DialogLoading.vue";
import SnackBar from "@/components/shared/SnackBar.vue";
import AlertNotification from "@/components/shared/AlertNotification.vue";
import {debug} from "@/store/config";
import {loadBaseResources} from "@/utils/resourcesLoader";

export default {
  name: 'App',

  components: {
    AlertNotification,
    SnackBar,
    DialogLoading,
    Navigation,
  },

  data() {
    return {
      isLoading: false,
      showSnackBar: false,
      snackBarColor: '',
      snackBarMessage: '',
      showAlertNotification: false,
      alertType: '',
      alertMessage: '',
      viewKey: 0
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'app/getIfAuthenticated',
      getLoadingTable: 'app/getLoadingTable',
    })
  },
  created() {
    let me = this
    let method = ''
    axios.interceptors.request.use(request => {
          if (debug === 'true') {
            request.url += (request.url.indexOf('?') === -1 ? '?' : '&') + 'XDEBUG_SESSION_START=PHPSTORM';
          }
          const isPagination = request.params?.isPagination
          if (isPagination) {
            this.$store.commit('app/setLastPaginatedEndpoint', request.url, {root: true})
          }
          // Token para las request
          const token = localStorage.getItem('user-token')
          if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
          }

          this.isLoading = true
          method = request.method
          return request
        },
        error => {
          return error
        }
    )
    axios.interceptors.response.use(response => {
          this.isLoading = false
          if (method !== 'get') {
            this.snackBarColor = 'success'
            this.snackBarMessage = 'Operación exitosa!'
            this.showSnackBar = true
            setTimeout(() => {
              this.showSnackBar = false
            }, 3000)
          }
          return response
        },
        error => {
          // console.log(error.response)
          this.isLoading = false
          if (error.response) {
            if (error.response.status === 307) {
              return error
            }
            if (error.response.status !== 200) {
              let errorMsg = error.response.data.message ?? error.response.data

              if (errorMsg instanceof ArrayBuffer) {
                const decoder = new TextDecoder('utf-8');
                const messageObject = JSON.parse(decoder.decode(errorMsg))
                errorMsg = messageObject.message
              }

              this.showAlertNotification = true;
              this.alertType = 'error';
              this.alertMessage = 'Error: ' + errorMsg;
            }
            if (error.response.status === 401) {
              me.$store.commit('app/logout')
              me.$router.push('/login').catch(() => {
              })
            }
          }
          throw error
        }
    )

    loadBaseResources()
  },
  mounted() {
    const dark = localStorage.getItem('dark_theme')
    this.$vuetify.theme.dark = dark === 'true'
  },
  methods: {}
};
</script>

<style>
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-blue.css";

</style>
